.hero {
    padding-top: 99px;
    height: 100vh;
    background-image: url(../images/hero-background.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &__text {
        padding-right: 0;
    }
    &__title {
        margin-bottom: 30px;
    }
    .button {
        margin-top: 40px;
    }
}

.scroll {
    font-size: 13px;
    line-height: 27px;
    letter-spacing: 2px;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 62px;
        background-color: $color_secondary;
        top: 35px;
    }
}


img.vert-move {
    position: absolute;
    top: 17%;
    right: 9%;
    width: 39vw;
}
.maskota {
    position: absolute;
    width: 24vw;
    right: 11%;
    bottom: 13%;
}

.join-us {
    position: fixed;
    left: 15px;
    bottom: 0;
    color: #6C54DC;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
}


/** Social **/
.social-networks {
    position: fixed;
    right: 30px;
    bottom: 0;
    li {
        list-style: none;
        margin-bottom: 20px;
        opacity: 0.3;
        transition: all linear 100ms;
        &:hover {
            opacity: 1;
        }
    }
}
