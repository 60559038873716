footer {
    background-image: url(../images/footerbg.jpg);
    background-repeat: no-repeat;
    background-position: center bottom;
    padding-bottom: 150px;
    position: relative;
}

.footer-content {
    margin-top: 340px;
    color: #dfe0d5;
    a {
        color: #dfe0d5;
    }
    .footer-nav {
        a {
            color: $color_primary;
            text-decoration: underline;
        }
    }
}
.powered-by {
    margin-bottom: 0;
}
.footer-logo {
    margin-bottom: 15px;
    display: inline-block;
}