.button-ticket {
    background-image: url(../images/ticket-button.png);
    color: $color_grey;
    min-width: 123px;
    text-align: center;
    background-position: center;
    background-repeat: no-repeat;
    padding: 5px 10px 6px 10px;
    letter-spacing: 1.5px;
    font-weight: bold;
}
.nav-logo img {
    max-height: 26px;
}

.nav-bar {
    -webkit-animation: fadeIn 1.5s ease;
    -moz-animation: fadeIn 1.5s ease;
    animation: fadeIn 1.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    padding: 30px;
    &.sticky {
        background-color: #070f30;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }
}

.top-nav {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.menu {
    margin: 0 auto;
    &__item {
        display: inline-block;
        &__link {
            color: rgba(223, 224, 213, 0.4);
            padding: 17px;
            &:hover,
            &.active {
                color: $color_grey;
            }
        }
        &:first-child a {
            padding-left: 0;
        }
        &:last-child a {
            padding-right: 0;
        }
    }
}

@-webkit-keyframes fadeIn {
    from {
        top: -50px;
    }
    to {
        top: 0;
    }
}
@-moz-keyframes fadeIn {
    from {
        top: -50px;
    }
    to {
        top: 0;
    }
}
@keyframes fadeIn {
    from {
        top: -50px;
    }
    to {
        top: 0;
    }
}

.mobile-social {
    display: none;
}


.tickets-section {
    margin-top: 150px;
    iframe {
        max-width: 720px;
        width: 100%;
        margin: auto;
        display: block;
        transition: all linear 200ms;
    }
}