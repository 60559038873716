.lineup-section {
    padding: 140px 0 240px 0;
    background-image: url(../images/speakers-text-bg.png), url(../images/speakers-top-bg.png),
        url(../images/bottom-speaker-bg.png);
    background-repeat: no-repeat;
    background-position: center 95px, center top, 35% bottom;
    //animation-name: load-speaker-bg;
    //animation-duration: 15s;
    //animation-fill-mode: forwards;
    background-size: auto, 80%, 60%;
    &__subtitle {
        color: $color_primary;
        font-size: 64px;
        margin-bottom: 25px;
        font-weight: 300;
    }
    .section-title {
        margin-bottom: 10px;
    }
}

@keyframes load-speaker-bg {
    from {
        background-size: auto, 0px, 0px;
    }
    to {
        background-size: auto, 80%, 60%;
    }
}

.all-speakers {
    position: relative;
    padding-bottom: 20px;
    &:before {
        content: '';
        position: absolute;
        display: block;
        width: 80%;
        border: 1px solid #00176b;
        height: 100%;
        left: 9%;
        top: 37px;
    }
    .col .row:first-child .col-sm-3 {
        padding-bottom: 50px;
        &:first-child {
            margin-top: 120px;
        }
        &:nth-child(2) {
            margin-top: 80px;
        }
        &:nth-child(3) {
            margin-top: 40px;
        }
        &:nth-child(6),
        &:nth-child(10),
        &:nth-child(14) {
            margin-top: -40px;
        }
        &:nth-child(7),
        &:nth-child(11),
        &:nth-child(15) {
            margin-top: -80px;
        }
        &:nth-child(8),
        &:nth-child(12),
        &:nth-child(16) {
            margin-top: -120px;
        }
    }
}

.speaker-box {
    display: block;
    &__details {
        margin-top: 10px;
        min-height: 100px;
        h5 {
            font-size: 21px;
            line-height: 26px;
            color: $color_brand;
        }
        h6 {
            font-size: 16px;
            line-height: 24px;
            font-weight: 300;
            font-style: italic;
            color: $color_brand;
        }
        span {
            font-size: 14px;
            text-transform: uppercase;
            opacity: 0.6;
            color: $color_grey;
            letter-spacing: 1.8px;
        }
    }
    &:hover {
        span {
            color: $color_primary;
        }
    }
}

.speakers-call {
    margin-top: 100px;
    h3 {
        font-size: 36px;
        color: $color_brand;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 35px;
    }
}

.speaker-modal .modal-content {
    background-color: $color_body_background;

    .modal-header,
    .modal-footer {
        border: 0;
    }

    .modal-title {
        color: $color_brand;
        font-size: 21px;
    }
    h3.modal-title {
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    h5.modal-title {
        font-size: 30px;
        margin: 15px 0 0 15px;
        span {
            display: block;
            font-size: 14px;
            color: $color_primary;
        }
    }
    img {
        margin-bottom: 15px;
    }
}
