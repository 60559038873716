.venue-section {
    background-image: url(../images/venue-text-bg.png), url(../images/venue-bg.png);
    background-repeat: no-repeat;
    background-position: center 10px, 56% 60%;
    padding-bottom: 200px;
    &__details {
        font-size: 21px;
        line-height: 31px;
        font-weight: 500;
    }
}

.venue-gallery {
    margin-top: 45px;
    .row:first-child {
        margin-bottom: 20px;
    }
    .img-fluid {
        width: 100%;
    }
}

#map {
    width: 100%;
    height: 440px;
}
