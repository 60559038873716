.button {
    border: none;
    display: inline-block;
    font-size: 21px;
    line-height: 24px;
    color: $color_button;
    padding: 20px 40px;
    position: relative;
    z-index: 1;
    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 10px;
        width: 100%;
        left: 10px;
        bottom: -10px;
        z-index: -1;
        opacity: 0.3;
        transition: all 300ms ease 0s;
    }
    &:hover:before {
        opacity: 0.5;
    }

    &--pink {
        background-color: $color_primary;
        &:before {
            background-color: $color_primary;
        }
    }

    &--blue {
        background-color: $color_brand;
        color: #000b6f;
        &:before {
            background-color: $color_brand;
        }
    }

    &--yellow {
        background-color: $color_accent;
        color: #00126d;
        &:before {
            background-color: $color_accent;
        }
    }
    &--temporary {
        margin-top: 40px;
    }

    .modal & {
        font-size: 18px;
        padding: 10px 20px;
        &:before {
            top: 5px;
            left: 5px;
            bottom: -5px;
        }
    }
}
