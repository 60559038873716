.agenda-section {
    background-image: url(../images/agenda-text-bck.png), url(../images/agenda-bck.png);
    background-repeat: no-repeat;
    background-position: 70% top, 80% 40%;
    padding-bottom: 240px;
}
.agenda {
    margin-top: 75px;
    h3 {
        font-size: 21px;
        color: $color_primary;
        margin-bottom: 40px;
    }
}

.agenda-details {
    font-size: 21px;
    line-height: 31px;
    font-weight: 500;
    margin-bottom: 20px !important;
}

.agenda-stage {
    border-left: 1px solid #00176b;
    padding-right: 80px;
    list-style: none;
    margin-left: -30px;
    li {
        padding-left: 30px;
        margin-bottom: 50px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            display: block;
            background-color: #00176b;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            left: -7.5px;
            top: 0;
        }
        h6 {
            color: #dfe0d5;
            font-size: 16px;
            margin-bottom: 10px;
        }
        h5 {
            font-size: 28px;
            color: $color_brand;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            color: $color_secondary;
        }
    }
    &--two {
        margin-top: 415px;
    }
}
