@font-face {
    font-family: FabrikatMono;
    src: url("../fonts/fabrikat-mono-regular.otf") format("opentype");
}

@font-face {
    font-family: FabrikatMono;
    font-weight: bold;
    src: url("../fonts/fabrikat-mono-bold.otf") format("opentype");
}

body {
    font-family: $font_family;
    font-size: $font_size;
    line-height: $font_line_height;
    color: $color_body_text;
    background: $color_body_background;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include breakpoint(mobile){
        font-size: 16px;
        line-height: 24px;
    }
}

a{
    color: $color_link;
    text-decoration: none;
    &:hover{
        color: $color_link_hover;
        text-decoration: none;
    }
}
a:active,
a:hover,
a:focus {
  outline: 0;
  -moz-outline-style: none;
}

p,
ul,
ol {
    margin: 0 0 24px 0;
}

p:last-child,
ul:last-child,
ol:last-child {
    margin-bottom: 0;
}

a, 
a img, 
button {
    transition: all 300ms ease 0s;
}

h1, 
h2, 
h3, 
h4, 
h5, 
h6 {
    line-height: 1.2;
}

h1 a, 
h2 a, 
h3 a, 
h4 a, 
h5 a, 
h6 a {
    color: $color_link;
}

h1 a:hover, 
h2 a:hover, 
h3 a:hover, 
h4 a:hover, 
h5 a:hover, 
h6 a:hover {
    color: $color_link_hover;
}

h1 {
    font-size: 72px;
    line-height: 80px;
}

h2 {
    font-size: 36px;
    color: $color_brand;
}

h3 {
    font-size: 21px;
}

h4 {
    font-size: 18px;
}

h5 {
    font-size: 14px;
}

h6 {
    font-size: 12px;
}