@media (max-width: 1024px) {
    h1 {
        font-size: 55px;
        line-height: 1.2;
    }
    .why-text {
        padding: 0;
    }
    #map {
        height: 350px;
    }
}

/** Tablet **/
@media (max-width: 768px) {
    .join-us {
        font-size: 14px;
        left: 2px;
    }
    .offset-left {
        padding-left: 15px;
    }
    .menu__item__link {
        padding: 12px;
    }
    .about-list {
        margin-left: 60px;
    }
    .conf-stats li span {
        font-size: 40px;
        padding-right: 12px;
    }
    .conf-stats li {
        font-size: 17px;
    }
    .lineup-section__subtitle {
        font-size: 50px;
    }
    #map {
        height: 250px;
    }
    .sponsor-list {
        &--supporter {
            li {
                max-width: 30%;
            }
        }
        &--friends {
            li {
                max-width: 30%;
            }
        }
    }
    .button-sponsor-top {
        float: none;
        margin: 20px 0 40px 0;
    }
    /** Jobs Page **/
    .job-accordion {
        img {
            max-width: 100%;
        }
        &__item {
            &:after {
                right: 25px;
                top: 20px;
            }
        }
    }
    .job-position {
        h3 {
            font-size: 24px;
        }
        &:after {
            right: 25px;
        }
    }
    .job-description {
        max-width: 100%;
        padding: 0 35px 90px 35px;
        margin: 0;
    }
}

/** Most Screens **/
@media (max-width: 736px) {
    .mobile-none {
        display: none;
    }
    .powered-by {
        margin: 10px 0;
    }
    .mobile-only {
        display: block;
    }
    .button {
        padding: 12px 28px;
        font-size: 18px;
    }

    /** Mobile Menu **/
    .topNavBtn span,
    .topNavBtn::after,
    .topNavBtn::before {
        transition: all 0.3s ease-in-out;
        background-color: #f29222;
        height: 1px;
    }
    .topNavBtn {
        position: absolute;
        width: 30px;
        right: 30px;
        top: 24px;
    }
    .topNavBtn span {
        display: block;
        margin: 8px 0;
        opacity: 1;
    }
    .topNavBtn::after,
    .topNavBtn::before {
        display: block;
        content: '';
    }
    .topNavBtn:hover span,
    .topNavBtn:hover::after,
    .topNavBtn:hover::before {
        background-color: #f29222;
    }
    .topNavBtn:focus span,
    .topNavBtn:focus::after,
    .topNavBtn:focus::before {
        background-color: #f5be58;
    }
    .topNavBtn.active span,
    .topNavBtn.active::after,
    .topNavBtn.active::before,
    .topNavBtn.active:focus span,
    .topNavBtn.active:focus::after,
    .topNavBtn.active:focus::before {
        background-color: #f29222;
    }
    .topNavBtn.active span,
    .topNavBtn.active:focus span {
        opacity: 0;
    }
    .topNavBtn.active::before,
    .topNavBtn.active:focus::before {
        -webkit-transform: translateY(9px) rotate(45deg);
        transform: translateY(9px) rotate(45deg);
    }
    .topNavBtn.active::after,
    .topNavBtn.active:focus::after {
        -webkit-transform: translateY(-9px) rotate(-45deg);
        transform: translateY(-9px) rotate(-45deg);
    }
    .topNavBtn {
        display: block;
        z-index: 1;
    }

    .top-nav {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(7, 15, 48, 0.95);
        text-align: center;
        padding: 70px 20px 30px 20px;
        display: none;
    }
    .menu__item {
        display: block;
        margin-bottom: 10px;
        &__link {
            padding: 2px 10px !important;
            color: rgba(223, 224, 213, 0.5);
        }
    }
    .button-ticket {
        padding: 10px 25px;
        display: inline-block;
        margin-top: 10px;
    }
    .nav-logo {
        z-index: 1;
    }
    .nav-bar {
        padding: 20px 30px 15px 30px;
        background: -webkit-linear-gradient(
            top,
            rgba(7, 15, 49, 1) 0%,
            rgba(7, 15, 49, 0.38) 39%,
            rgba(7, 15, 49, 0.21) 54%,
            rgba(7, 15, 49, 0) 89%
        );
    }
    .scroll:after {
        height: 40px;
    }

    /** Mobile Social **/
    .social-networks {
        display: none;
    }
    .mobile-social {
        display: block;
        margin-top: 20px;
        li {
            display: inline-block;
            margin: 0 5px;
            opacity: 0.5;
            img {
                max-width: 20px;
            }
        }
    }

    h1 {
        font-size: 40px;
    }

    /** Hero **/
    .hero {
        background-image: url(../images/hero-mobile2.png);
        background-size: cover;
        background-position: right -150px;
        padding: 155px 0 100px 0;
        height: auto;
    }
    .hero__title .primary-color {
        display: block;
    }
    .hero__title br {
        display: none;
    }
    .hero .button {
        margin-top: 10px;
    }
    .scroll {
        margin-bottom: 0;
    }
    /** About Section **/
    .about-section {
        background-image: none;
        padding: 60px 0 120px 0;
    }
    .why-text {
        margin-bottom: 30px;
    }
    .about-list h3 span {
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-right: 8px;
    }
    .conf-stats li {
        margin-right: 28px;
    }
    .conf-stats li:last-child {
        margin-right: 0;
    }
    .conf-stats {
        padding-bottom: 0;
    }

    /** Lineup Section **/
    .lineup-section {
        background-image: none;
        padding: 20px 0 120px 0;
    }
    .lineup-section__subtitle {
        font-size: 30px;
        margin-bottom: 40px;
    }
    .all-speakers {
        padding: 0 15px 70px 15px;
        text-align: center;
    }
    .speakers-call {
        margin-top: 60px;
    }
    .all-speakers:before {
        width: 106%;
        top: 0;
        left: -3%;
    }

    /** Venue **/
    .venue-section {
        background-image: none;
        padding: 20px 0 120px 0;
    }
    .venue-section br {
        display: none;
    }
    .venue-section__details {
        margin-bottom: 25px !important;
    }
    .venue-section__details span {
        display: block;
    }

    /** Sponsors **/
    .sponsors-section {
        background-image: none;
        padding: 20px 0 120px 0;
        br {
            display: none;
        }
    }

    /** Subscribe **/
    .subscribe-section {
        background-image: none;
        padding-top: 20px;
    }
    .subscribe-intro {
        font-size: 20px;
        line-height: 1.3;
        margin-bottom: 80px;
    }
    .subscribe-form {
        border-bottom: 0;
    }
    .subscribe-form input {
        width: 100% !important;
        opacity: 0.6;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        font-size: 18px;
        margin-bottom: 20px;
    }

    /** Footer **/
    footer {
        padding-bottom: 80px;
    }
    .footer-nav {
        margin: 20px 0;
    }
    footer address {
        margin-top: 20px;
    }

    /** Agenda **/
    .agenda-section {
        background-image: none;
    }
    .agenda-stage {
        padding-right: 20px;
        margin-left: 0;
        margin-bottom: 80px;
        li {
            h5 {
                font-size: 22px;
            }
        }
        &--two {
            margin-top: 0;
        }
    }
}

@media (max-width: 570px) {
    .all-speakers .col .row:first-child .col-sm-3 {
        margin: 0 !important;
        padding-bottom: 50px;
    }
    .speaker-box {
        &__details {
            min-height: 1px;
            a {
                display: inline-block;
                margin-top: 10px;
            }
        }
    }
    .agenda-section {
        padding-bottom: 0;
    }
    .agenda-stage {
        margin-bottom: 120px !important;
    }
}

@media (max-width: 500px) {
    .hero {
        /*background-size: auto;
        background-position: -200px -200px;*/
    }
    .join-us {
        display: none;
    }
    .sponsor-list {
        &--supporter {
            li {
                max-width: 42%;
                margin-right: 6%;
            }
        }
        &--friends {
            li {
                max-width: 45%;
                margin-right: 5%;
            }
        }
    }
    /** Jobs Page **/
    .job-accordion {
        img {
            max-width: 160px;
            margin-bottom: 10px;
        }
    }
    /** Tickets **/
    .ticket-desc h3 {
        font-size: 21px;
        padding: 0 10px;
        br {
            display: none;
        }
    }
}

/** Landscape **/
@media only screen and (max-device-width: 736px) and (orientation: landscape) {
    .hero {
        height: auto;
        background-size: contain;
        background-position: right top;
    }
}
