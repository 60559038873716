.sponsors-section {
    background-image: url(../images/sponsors-text-bg.png), url(../images/sponsors-bg.png);
    background-repeat: no-repeat;
    background-position: center 20px, center top;
    padding: 50px 0 220px 0;
    background-size: auto, 50%;
}

.sponsors-desc {
    max-width: 400px;
    display: inline-block;
}
.button-sponsor-top {
    margin-top: 0;
    float: right;
}

.sponsor-type {
    margin-top: 50px;
    h6 {
        font-size: 21px;
        color: $color_primary;
        margin-bottom: 35px;
    }
}

.sponsor-list {
    margin-bottom: 30px;
    li {
        display: inline-block;
        vertical-align: middle;
        margin: 0 25px 25px 0;
        &:last-child {
            margin-right: 0;
        }
    }
    &--platinum {
        img {
            max-height: 92px;
        }
    }
    &--gold {
        img {
            max-height: 82px;
        }
    }
    &--activist {
        img {
            max-height: 75px;
        }
    }
    &--supporter {
        img {
            max-height: 62px;
        }
    }
    &--friends {
        margin-bottom: 0 !important;
        li {
            margin: 0 45px 50px 0;
        }
        img {
            max-height: 45px;
        }
    }
    .img-opacity {
        opacity: 0.5;
    }
}

/**** Tickets ****/
.tickets-section {
    margin-bottom: 100px;
}
.ticket-desc {
    text-align: center;
    h1 {
        margin-bottom: 20px;
        span {
            color: #D31659;
        }
    }
    h3 {
        font-size: 24px;
        line-height: 1.6;
        margin: 0 0 90px 0;
        span {
            color: #1FC7D8;
            &:last-child {
                font-weight: bold;
            }
        }
    }
}