.about-section {
    background-image: url(../images/init-2019.png),
        url(../images/about-lines.png);
    background-repeat: no-repeat;
    background-position: center 120px, 79% 35px;
    background-size: auto, 50%;
    //animation-name: loadbg;
    //animation-duration: 7s;
    //animation-iteration-count: infinite;
    //animation-fill-mode: forwards;
    padding: 140px 0 30px 0;
}

@keyframes loadbg {
    from {
        background-size: auto, 0px;
    }
    to {
        background-size: auto, 50%;
    }
}

.hashtag-share {
    font-size: 36px;
    color: $color_primary;
    margin-bottom: 30px;
    span {
        display: block;
        font-size: 13px;
        letter-spacing: 2px;
        color: $color_secondary;
    }
}

.why-text {
    padding-right: 110px;
}

.about-list {
    margin-left: 105px;
    li {
        margin-bottom: 25px;
        list-style: none;
    }
    h3 {
        font-weight: bold;
        color: $color_primary;
        text-transform: uppercase;
        margin-bottom: 5px;
        margin-left: -60px;
        span {
            display: inline-block;
            width: 48px;
            height: 48px;
            border: 1px solid;
            border-color: $color_primary;
            text-align: center;
            line-height: 48px;
            border-radius: 50%;
        }
    }
}

.conf-stats {
    margin: auto;
    display: flex;
    justify-content: space-around;
    background-image: url(../images/stats-about-bg.png);
    background-repeat: no-repeat;
    padding-bottom: 50px;
    background-position: 25% center;
    animation-name: loadstatbg;
    animation-duration: 8s;
    background-size: 0px;
    animation-fill-mode: forwards;
    li {
        font-size: 21px;
        display: inline-block;
        span {
            display: inline-block;
            font-size: 56px;
            padding-right: 25px;
            vertical-align: middle;
        }
        &:nth-child(2) {
            padding-top: 30px;
        }
        &:nth-child(3) {
            padding-top: 60px;
        }
        &:last-child {
            padding-top: 90px;
        }
    }
}
@keyframes loadstatbg {
    from {
        background-size: 0px;
    }
    to {
        background-size: 35%;
    }
}
