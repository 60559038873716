/*** Helpers ***/
.left { 
	position: relative; 
	float: left; 
} 

.right { 
	position: relative; 
	float: right; 
} 

.no-margin { 
	margin-right: 0px; 
	margin-left: 0px; 
}

.mobile-only {
	display: none;
}

.text-center {
	text-align: center;
}

.primary-color {
	color: $color_primary;
}

.secondary-color {
	color: $color_secondary;
}

.brand-color {
	color: $color_brand;
}

.accent-color {
	color: $color_accent;
}

.offset-left {
	padding-left: 120px;
}

.bottom-align {
	align-self: flex-end;
}

.section-title {
	position: relative;
	padding-left: 105px;
	margin-bottom: 40px;
	&:after {
		content: '';
		position: absolute;
		display: block;
		background-color: $color_brand;
		height: 1px;
		width: 62px;
		left: 0;
		top: 50%;
	}
}